import { createWebHistory, createRouter } from "vue-router"
import { cmsRoutes } from "@/routes/cms.route"
import { patientRoutes } from "@/routes/patient.route"
import { CmsUserStore } from "@/store/api_v1/cms"
import { PatientPatientStore } from "@/store/api_v1/patient"

const NotFoundPage = () => import('@/components/app/http/NotFoundPage')
const NotFoundPageCms = () => import('@/components/app/http/NotFoundPageCms')
const NotFoundPagePatient = () => import('@/components/app/http/NotFoundPagePatient')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...cmsRoutes,
    ...patientRoutes,
    {
      path: `/404`,
      name: 'http.404',
      component: NotFoundPage,
    },
    {
      path: `/cms/404`,
      name: 'cms.http.404',
      component: NotFoundPageCms,
    },
    {
      path: `/patient/404`,
      name: 'patient.http.404',
      component: NotFoundPagePatient,
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404'
    },
    {
      path: '/cms/:pathMatch(.*)*',
      redirect: '/cms/404'
    },
    {
      path: '/patient/:pathMatch(.*)*',
      redirect: '/patient/404'
    },
    // { path: '/', redirect: '/' },
  ],
})

router.beforeEach(async (to, from, next) => {

  let app = window.location.host.split('.')[0]
  if (app === 'cms' || app === 'fgc-cms') {
    const token = !!(localStorage.getItem('access_token') || CmsUserStore.state.user?.access_token),
          user = !!(localStorage.getItem('user')),
          loginPage = to.path === '/auth' || to.path === '/auth/new-password',
          authPages = to.path.startsWith('/auth')

    if (!token && !loginPage) {
      next({ name: 'cms.auth' })
    } else if (!user && !authPages) {
      next({ name: 'cms.auth' })
    } else if (token && user && authPages && !to.path.startsWith('/auth/new-password')) {
      next({ name: 'cms.requests' })
    } else {
      next()
    }
  } else if (app === 'patient-portal' || app === 'fgc-patient-portal') {
    const tokenPatient = !!(localStorage.getItem('patient_access_token') || PatientPatientStore.state.patient?.access_token),
          agreement = JSON.parse(localStorage.getItem('agreement')) || $store.getters["PatientInitialStore/getAgreement"],
          isAgree = agreement ? !Object.values(agreement).some((i) => !i) : false,
          loginPages = ['/auth', '/auth/code'].includes(to.path),
          profilePage = to.path === "/auth/user",
          notifyPage = to.path === "/auth/notify",
          confirmPages = to.path.startsWith('/user/confirm'),
          match = {
            'patient.confirm.consent': 'consent_form_read_at',
            'patient.confirm.contras': 'contraindications_read_at',
            'patient.confirm.time': 'time_requirements_read_at',
            'patient.confirm.test': 'test_restrictions_read_at',
          }

    if (!$store.getters["PatientInitialStore/getIsInit"]) {
      await $store.dispatch("PatientInitialStore/fetchInit")
    }
    if (!(tokenPatient || agreement) && !loginPages) {
      next({ name: 'patient.auth' })
    } else if (!isAgree && !(loginPages || profilePage || notifyPage)) {
      const dis = Object.entries(match).find(([k, v]) => agreement[v] === null)
      next(to.name === dis[0] ? null : { name: dis[0] })
    } else if (
        localStorage.getItem('test_preference') === 'patient.llm' && 
        !localStorage.getItem('llm_agreement') && 
        to.name === 'patient.llm'
      ) {
      next({ name: 'patient.confirm.llm' })
    } else if (isAgree && confirmPages) {
      next({ name: localStorage.getItem('test_preference') ?? 'patient.samples' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "logo flex",
  style: {"flex-direction":"column"}
}
const _hoisted_2 = {
  class: "logo flex",
  style: {"flex-direction":"column"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "msg" }
const _hoisted_5 = { style: {"font-weight":"700"} }
const _hoisted_6 = { class: "flex" }

import { computed, nextTick, onMounted, ref, watch } from 'vue';
import * as api from './api-stepan';
import { toast } from  '@/helpers/error.helper';
import { Button, Spin } from '@/shared/ui';
// @ts-ignore */
import { VMarkdownView } from 'vue3-markdown'
import { useRoute, useRouter } from 'vue-router';
import { useLLMStore } from '@/entities/llm';


export default /*@__PURE__*/_defineComponent({
  __name: 'TestPageStepan',
  setup(__props) {

const loading = ref(false);
const text = ref('');
const messages = ref<any>([]);
const llmStore = useLLMStore();
const wrapper = ref();

const thread_run = ref('');
const run_run = ref('');
const thread_to_terminate = ref('');

async function sendMsg() {
  if (!text.value || loading.value) return;
  llmStore.createMessage(text.value).then(async () => {
    text.value = '';
    await fetchMessages();
    nextTick(() => {
      wrapper.value.scrollTop = wrapper.value.scrollHeight;
    });
  });
}

function checkRun() {
  llmStore.checkRun(thread_run.value, run_run.value).then(async () => {
    await fetchMessages();
  });
}
function deleteThread() {
  llmStore.deleteThread(thread_to_terminate.value)
}

async function fetchMessages() {
  loading.value = true;
  try {
    const res = await llmStore.fetchThread();
    messages.value = res;
  } catch (e : any) {
    toast(e);
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  await fetchMessages();
});

watch(() => messages.value.length, () => {
  nextTick(() => {
    wrapper.value.scrollTop = wrapper.value.scrollHeight;
  });
});

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, {
    class: "main",
    style: {"min-height":"100vh"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_sider, { width: "400" }, {
        default: _withCtx(() => [
          _cache[8] || (_cache[8] = _createElementVNode("div", {
            class: "logo flex",
            style: {"padding":"10px"}
          }, "LLM Testing Page", -1)),
          _createElementVNode("div", _hoisted_1, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { style: {"margin":"10px"} }, "Runs", -1)),
            _createVNode(_component_a_input, {
              style: {"padding":"5px","margin-bottom":"10px"},
              placeholder: 'thread',
              value: thread_run.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((thread_run).value = $event))
            }, null, 8, ["value"]),
            _createVNode(_component_a_input, {
              style: {"padding":"5px"},
              placeholder: 'run',
              value: run_run.value,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((run_run).value = $event))
            }, null, 8, ["value"]),
            _createVNode(_unref(Button), {
              style: {"margin":"20px"},
              onClick: checkRun
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Check Run ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { style: {"margin":"10px"} }, "Terminate", -1)),
            _createVNode(_component_a_input, {
              style: {"padding":"5px"},
              placeholder: 'thread',
              value: thread_to_terminate.value,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((thread_to_terminate).value = $event))
            }, null, 8, ["value"]),
            _createVNode(_unref(Button), {
              style: {"margin":"20px"},
              onClick: deleteThread
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Terminate Thread ")
              ])),
              _: 1
            })
          ]),
          (loading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_unref(Spin), { size: '80px' })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_a_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_content, null, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "wrapper",
                ref_key: "wrapper",
                ref: wrapper
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages.value, (msg) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(msg.role), 1),
                      _cache[9] || (_cache[9] = _createTextVNode()),
                      _createElementVNode("span", null, "(" + _toDisplayString(msg.created_at) + ")", 1)
                    ]),
                    _createVNode(_unref(VMarkdownView), {
                      content: msg.content
                    }, null, 8, ["content"])
                  ]))
                }), 256))
              ], 512)
            ]),
            _: 1
          }),
          _createVNode(_component_a_layout_footer, { style: {"text-align":"center"} }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_a_input, {
                  placeholder: "Your message",
                  value: text.value,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((text).value = $event)),
                  onKeyup: _withKeys(sendMsg, ["enter"])
                }, null, 8, ["value"]),
                _createVNode(_unref(Button), {
                  style: {"margin":"10px"},
                  onClick: sendMsg,
                  disabled: loading.value || !text.value
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode("Send message")
                  ])),
                  _: 1
                }, 8, ["disabled"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})
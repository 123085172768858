import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './nurse-portrait.png'


const _hoisted_1 = { class: "portrait_wrapper" }


export default /*@__PURE__*/_defineComponent({
  __name: 'LLMPortrait',
  props: ['test_type'],
  setup(__props) {

  

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      class: "portrait",
      src: _imports_0
    }, null, -1),
    _createTextVNode(" Your Personal "),
    _createElementVNode("br", null, null, -1),
    _createTextVNode(" Testing Assistant ")
  ])))
}
}

})
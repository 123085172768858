import { defineStore } from 'pinia';
import * as api from '../api';
import { ref } from 'vue';
import { initWSToken, ws } from '@/shared/api';

export const useLLMStore = defineStore('availability', () => {
  const isRunning = ref(false);
  const isInit = ref(false);
  const thread = ref<{
    id?: number;
    content: string;
    created_at?: string;
    role?: string;
  }[]>([]);
  const chat_completed = ref(false);

  async function fetchThread() {
    const response = await api.fetchMessages();
    response.reverse();
    return thread.value = response;
  }

  async function checkRun(thread : string, run : string) {
    const response = await api.checkRun(thread, run);
    return response;
  }

  async function createThread() {
    return await api.createThread();
  }

  async function toggleThread(active : boolean) {
    return await api.toggleThread(active);
  }

  async function createMessage(message: string) {
    return await api.createMessage(message);
  }

  async function deleteThread(thread: string) {
    return await api.deleteThread(thread);
  }
  async function startTest() {
    return await api.startTest();
  }

  function initStore() {
    isInit.value = true
    initWSToken()
    const barcode = JSON.parse(localStorage.getItem('test') || '{}')?.barcode
    ws?.private('thread.' + barcode).listen('RunCompleted', async (e : {
      chat_completed: boolean
      barcode: string
    }) => {
      chat_completed.value = e.chat_completed
      await fetchThread()
      isRunning.value = false
    })
    ws?.private('thread.' + barcode).listen('RunStarted', async (e : any) => {
      isRunning.value = true
    })
  }

  return { initStore, isInit, isRunning, thread, chat_completed, fetchThread, createThread, toggleThread, createMessage, checkRun, deleteThread, startTest };
});
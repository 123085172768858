import { getGetters } from '@/store/getters'
import { getMutations } from '@/store/mutations'
import { getActions } from '@/store/actions'
import { Sample as model } from '@/models/api_v1'
import { PatientSamplesService as service } from '@/services/api_v1/patient'

// Init
const getInitialState = () => ({
  isInit: null,
  items: null,
  itemsMap: {},
  itemInit: model.initialState(),
});

// Import
const getters = getGetters()
const mutations = getMutations({ model })
const actions = {
  ...getActions({ model, service, init: getInitialState() }),
  async labeled({ state, commit, dispatch }, sample_number) {
    let output = null

    try {
      output = await service.labeled(sample_number)
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },
  async collected({ state, commit, dispatch }, sample_number) {
    let output = null

    try {
      output = await service.collected(sample_number)
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },
}

// Export
export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}

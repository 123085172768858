import { http } from "@/shared/api";

const llm_url = process.env.VUE_APP_DEV_MODE === 'true' ? 'http://llm-microservice.local/api/v1/' : 'https://fgc-dev.rbbrduck.co.uk/ms/llm/api/v1/';
const app_url = process.env.VUE_APP_URL

export const fetchThreads = async () => {
  const response = await http.get(llm_url + 'threads');
  return response.data.data;
}
export const fetchMessages = async () => {
  const response = await http.get(llm_url + 'threads/msg');
  return response.data.data;
}
export const createMessage = async (message : string) => {
  const response = await http.post(llm_url + 'threads/messages/create', {
    message
  });
  return response.data;
}
export const createThread = async () => {
  const response = await http.post(llm_url + 'threads/create');
  return response.data;
}
export const checkRun = async (thread: string, run: string) => {
  const response = await http.post(llm_url + 'threads/cancel', {
    thread,
    run
  });
  return response.data;
}
export const deleteThread = async (thread: string) => {
  const response = await http.post(llm_url + 'threads/terminate', {
    thread,
  });
  return response.data;
}
export const toggleThread = async (active : boolean) => {
  if(active){
    await http.post(llm_url + 'threads/active');
    await http.post(llm_url + 'threads/toggle/');
  } else {
    await http.post(llm_url + 'threads/inactive');
  }
}
export const startTest = async () => {
  const response = await http.post(app_url + 'api/v1/patient/request/start');
  return response.data;
}
<template>
<AuthLayout>
<template #content>
  
  <div class="SamplesManualsPage">
    
    <div class="SamplesManualsPage__title">
      {{ match[$route.name].title }}
    </div>
    
    <div class="SamplesManualsPage__body">
      <Templater
        :match="match"
        @onSubmit="onSubmit"
      />
    </div>
    
  </div>
  
</template>
</AuthLayout>
</template>

<script>
import AuthLayout from "@/components/patient/auth.layout/AuthLayout"
import Templater from "@/ui-kit/templater/Templater"
import collect from '@/components/patient/samples/collect.json'
import rtrn from '@/components/patient/samples/return.json'
import { mapActions, mapGetters } from "vuex"

export default {
  name: "SamplesManualsPage",
  components: {
    Templater,
    AuthLayout,
  },
  computed: {
    ...mapGetters({
      allSamples: 'PatientSampleStore/getAll',
      test: 'PatientPatientStore/getTest',
    }),
    baseline() {
      return this.test ? JSON.parse(this.test.content).find((i) => i.type === "baseline").text : []
    },
    label() {
      return this.test ? JSON.parse(this.test.content).find((i) => i.type === "label").text : []
    },
    substrate() {
      return this.test ? JSON.parse(this.test.content).find((i) => i.type === "substrate").text : []
    },
    match() {
      return {
        'patient.samples.baseline': {
          title: 'Baseline Sample\nInstructions',
          body: this.baseline,
          route: { name: 'patient.samples.collect' },
          button: 'Next',
        },
        'patient.samples.collect': {
          title: 'How to Collect Your\nBreath Samples',
          body: collect,
          route: { name: 'patient.samples.label' },
          button: 'Next',
        },
        'patient.samples.label': {
          title: 'How to Label Your\nBreath Samples',
          body: this.label,
          route: !!this.firstSample?.sample_added_at ? { name: 'patient.samples' } : { name: 'patient.sample', params: { sample: 1 } },
          button: 'Got it',
        },
        'patient.samples.substrate': {
          title: 'Substrate Intake',
          body: this.substrate,
          route: { name: 'patient.samples' },
          button: 'Done',
        },
        'patient.samples.return': {
          title: 'How to Return Your\nBreath Samples',
          body: rtrn,
          route: { name: 'patient.auth' },
          button: 'Done',
        },
      }
    },
    firstSample() {
      if (this.allSamples) {
        return this.allSamples.find((i) => i.number === 1)
      }
    },
  },
  methods: {
    ...mapActions({
      sampleFetch: 'PatientSampleStore/fetch',
      substrateConfirm: 'PatientRequestStore/substrate',
      returnConfirm: 'PatientRequestStore/returnConfirm',
    }),
    onSubmit() {
      if(this.$route.name === 'patient.samples.substrate') {
        this.substrateConfirm()
      } 
      if(this.$route.name === 'patient.samples.return') {
        this.returnConfirm()
      } 
      this.$router.push(this.match[this.$route.name].route)
    }
  },
  async mounted() {
    if (!this.allSamples) {
      await this.sampleFetch()
    }
  }
}
</script>

<style lang="scss" scoped>
.SamplesManualsPage {
  padding: 0 15px;
  
  &__body {
    padding: 14px 0 24px 0
  }
  
  &__title {
    color: $polarGreen7;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    white-space: break-spaces;
  }
  
}
</style>
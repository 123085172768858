<template>
<AuthLayout
  :overlay="tokenPatient && !hideModal || testCompleted"
>
<template #content>
  
  <template v-if="tokenPatient && $route.name === 'patient.auth' || testCompleted">
    <div
      class="LoginPage__modal LoginPage__progress"
      v-if="request.status === 'draft' && !hideModal"
    >
      <div class="LoginPage__message">
        <img
          class="LoginPage__info-icon"
          src="@/assets/img/info.svg"
        >
        <div class="LoginPage__text">
          Your test is already in progress.<br>
          Want to continue?
        </div>
      </div>
      <div class="LoginPage__buttons">
        <a-button
          class="LoginPage__button"
          @click="hideModal = true"
        >
          No
        </a-button>
        <a-button
          class="LoginPage__button"
          type="primary"
          @click="continueTest"
        >
          Yes
        </a-button>
      </div>
    </div>
    
    <div
      class="LoginPage__modal LoginPage__completed"
      v-if="(request.status !== 'draft' || testCompleted) && !hideModal"
    >
      <div class="LoginPage__message">
        <img
          class="LoginPage__info-icon"
          src="@/assets/img/info.svg"
        >
        <div class="LoginPage__text">
          Your test is already completed
        </div>
      </div>
      <div class="LoginPage__buttons">
        <a-button
          class="LoginPage__button"
          type="primary"
          @click="hideModal = true; testCompleted = false"
        >
          Ok
        </a-button>
      </div>
    </div>
  </template>
  
  <div class="LoginPage">
    
    <div class="LoginPage__title">
      {{ match[$route.name].title }}
    </div>
    
    <component :is="match[$route.name].component" @testCompleted="handleTestCompleted" />
    
  </div>
  
</template>
</AuthLayout>
</template>

<script>
import AuthLayout from "@/components/patient/auth.layout/AuthLayout"
import LoginFormAuth from "@/components/patient/auth/LoginForm/LoginFormAuth"
import LoginFormCode from "@/components/patient/auth/LoginForm/LoginFormCode"
import LoginFormPatient from "@/components/patient/auth/LoginForm/LoginFormPatient"
import LoginFormNotify from "@/components/patient/auth/LoginForm/LoginFormNotify"
import { PatientPatientStore } from "@/store/api_v1/patient"
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  name: "LoginPage",
  components: {
    AuthLayout,
    LoginFormAuth,
    LoginFormCode,
    LoginFormPatient,
    LoginFormNotify,
  },
  data: function () {
    return {
      hideModal: false,
      testCompleted: false,
      match: {
        'patient.auth': {
          title: 'Please Enter Your\nPersonal Details',
          component: 'LoginFormAuth',
        },
        'patient.auth.code': {
          title: 'Please Enter\nVerification Code',
          component: 'LoginFormCode',
        },
        'patient.auth.user': {
          title: 'Please Enter Your\nPersonal Details',
          component: 'LoginFormPatient',
        },
        'patient.auth.notify': {
          title: 'Please Select\nNotification Settings',
          component: 'LoginFormNotify',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      isRequest: 'PatientRequestStore/getIsInit',
      request: 'PatientRequestStore/getInit',
    }),
    tokenPatient() {
      return !!(localStorage.getItem('patient_access_token') || PatientPatientStore.state.patient?.access_token)
    }
  },
  methods: {
    ...mapActions({
      fetchRequest: 'PatientRequestStore/fetchInit',
      patientLogout: 'PatientPatientStore/logout',
    }),
    ...mapMutations({
      resetPatient: 'PatientPatientStore/resetPatient',
      resetTest: 'PatientPatientStore/resetTest',
    }),
    async onLogout() {
      await this.patientLogout()
        .then((response) => {
          if (response instanceof Error || response?.data?.data?.success === false) throw response
          this.resetPatient()
          this.resetTest()
          localStorage.removeItem('patient_access_token')
          this.hideModal = true
          this.testCompleted = true
        })
    },
    continueTest() {
      this.$router.push({ name: localStorage.getItem('test_preference') ?? 'patient.samples' })
    },
    handleTestCompleted() {
      this.hideModal = false
      this.testCompleted = true
    },
  },
  async mounted() {
    if (this.tokenPatient && !this.isRequest) {
      await this.fetchRequest()
    }
  }
}
</script>

<style lang="scss" scoped>
.LoginPage {
  padding: 0 15px;
  
  &__title {
    color: $polarGreen7;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    white-space: break-spaces;
  }
  
  &__modal {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: calc(100% - 30px);
    background-color: white;
    margin: 15px;
    padding: 24px;
    box-sizing: border-box;
    gap: 10px;
  }
  
  &__message {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    align-items: baseline;
  }
  
  &__buttons {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 15px;
  }
  
  &__info-icon {
    position: relative;
    bottom: -2px;
  }
  
}
</style>
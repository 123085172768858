import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"padding":"0 15px"} }

import AuthLayout from '@/components/patient/auth.layout/AuthLayout.vue';
import LLMPortrait from './chat/LLMPortrait.vue';
import { useRouter } from 'vue-router';
import LLMChat from './chat/LLMChat.vue';
import '@/assets/sass/llm.scss';
import { useLLMStore } from '@/entities/llm';
import { Spin } from '@/shared/ui';
import { onMounted, ref } from 'vue';
import { toast } from '@/shared/lib';


export default /*@__PURE__*/_defineComponent({
  __name: 'LLMPage',
  setup(__props) {

const router = useRouter();
const loading = ref(false);
const llmStore = useLLMStore();
const test_type = JSON.parse(localStorage.getItem('test') || '{}')?.title

async function switchToPortal() {
  if(loading.value) return
  localStorage.setItem('test_preference', 'patient.samples')
  loading.value = true
  try {
    await llmStore.toggleThread(false)
    await router.push({ name: 'patient.samples' });
  } catch {
    toast({ msg: 'Failed to switch to patient portal' })
  } finally {
    loading.value = false
  }
}
onMounted(async () => {
  const preference = localStorage.getItem('test_preference')
  if(preference === 'patient.samples') {
    loading.value = true
    await router.push({ name: 'patient.samples' });
    loading.value = false
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AuthLayout, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Aida GPT", -1)),
        _createVNode(LLMPortrait, {
          test_type: _unref(test_type),
          style: {"margin":"10px 0"}
        }, null, 8, ["test_type"]),
        _createElementVNode("div", {
          class: "switchButton",
          onClick: switchToPortal
        }, [
          _cache[0] || (_cache[0] = _createTextVNode("Switch to Patient Portal ")),
          (loading.value)
            ? (_openBlock(), _createBlock(_unref(Spin), {
                key: 0,
                size: '16px'
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("hr", { class: "divider" }, null, -1)),
      _createVNode(LLMChat)
    ]),
    _: 1
  }))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './friendly.svg'
import _imports_1 from './easy.svg'
import _imports_2 from './comprehensive.svg'


const _hoisted_1 = { class: "text" }

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs'
import LLMPortrait from '@/components/patient/llm/chat/LLMPortrait.vue';
import { useLLMStore } from '@/entities/llm';


export default /*@__PURE__*/_defineComponent({
  __name: 'LLM',
  setup(__props) {

const test_type = JSON.parse(localStorage.getItem('test') || '{}')?.title
const router = useRouter()
const llmStore = useLLMStore()

const check = ref(false)
function agreeToLLM() {
  localStorage.setItem('llm_agreement', dayjs().format('YYYY-MM-DDTHH:mm:ss'))
  llmStore.initStore()
  llmStore.startTest()
  router.push({name: 'patient.llm'})
}

return (_ctx: any,_cache: any) => {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(LLMPortrait, { test_type: _unref(test_type) }, null, 8, ["test_type"]),
    _createElementVNode("div", _hoisted_1, " Aida GPT is here to guide you through your " + _toDisplayString(_unref(test_type)) + " test journey with friendly, easy-to-follow instructions. She'll cover everything from preparation to restrictions, ensuring you feel supported every step of the way. ", 1),
    _cache[3] || (_cache[3] = _createStaticVNode("<div data-v-0afa7d10><b style=\"font-size:18px;\" data-v-0afa7d10>Key Benefits:</b><ul class=\"list text\" data-v-0afa7d10><li data-v-0afa7d10><img src=\"" + _imports_0 + "\" data-v-0afa7d10>Friendly Support </li><li data-v-0afa7d10><img src=\"" + _imports_1 + "\" data-v-0afa7d10>Easy-to-Follow Instructions</li><li data-v-0afa7d10><img src=\"" + _imports_2 + "\" data-v-0afa7d10>Comprehensive Coverage from Prep to Restrictions</li></ul></div><div data-v-0afa7d10><b style=\"font-size:18px;\" data-v-0afa7d10>Agreement</b><div data-v-0afa7d10>Please accept the <b data-v-0afa7d10>Terms</b> to proceed. Aida&#39;s guidance complements, not replaces, medical advice</div></div>", 2)),
    _createVNode(_component_a_checkbox, {
      class: "ant-checkbox-green Templater__checkbox",
      checked: check.value,
      "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((check).value = $event)),
      size: "large"
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "checkbox__text" }, [
          _createTextVNode("I agree to the "),
          _createElementVNode("a", {
            target: "_blank",
            href: "http://patient-portal.fgc.local:8080/confirm/llm"
          }, "Terms and Conditions")
        ], -1)
      ])),
      _: 1
    }, 8, ["checked"]),
    _createVNode(_component_a_button, {
      class: "ant-btn-huge Templater__submit",
      type: "green",
      size: "large",
      disabled: !check.value,
      onClick: agreeToLLM
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode(" Start Your Journey ")
      ])),
      _: 1
    }, 8, ["disabled"])
  ], 64))
}
}

})
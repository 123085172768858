import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "doneMsg"
}

import { toast } from '@/shared/lib';
import { Button, ChatButton } from '@/shared/ui/';
import { nextTick, onMounted, ref, watch, computed } from 'vue';
import LLMMessage from './LLMMessage.vue';
import { useLLMStore } from '@/entities/llm';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'LLMChat',
  setup(__props) {

const wrapper = ref()
const text = ref('')
const router = useRouter()
const store = useStore()

const llmStore = useLLMStore()
const { isRunning, thread, chat_completed } = storeToRefs(llmStore)

const isScrolledDown = ref(false)

function scrollToBottom() {
  wrapper.value.scrollTop = wrapper.value?.scrollHeight;
}

const suggestions = computed(() => {
  const lastMessage = thread.value[thread.value.length - 1]?.content;
  const match = lastMessage?.match(/["'*]how to ([\w\s]*)["'*]/i);
  if (match) {
    return [
      'Done',
      'I’m ready',
      `how to ${match[1]}` // Extracted string without quotes
    ];
  } else {
    return [
      'Done',
      'I’m ready',
      'I feel unwell',
    ];
  }
});

async function submit(){
  if(isRunning.value || !text.value) return
  const textPayload = text.value
  thread.value.push({
    role: 'user',
    content: textPayload,
  })
  text.value = ''
  try {
    const result = await llmStore.createMessage(textPayload)
    if(result.success){
      await llmStore.fetchThread()
    } else {
      toast({ msg: result })
    }
  } catch(error : any){
    toast({ msg: error })
  } 
} 

function doneHandler(){
  router.push({ name: 'patient.auth' })
}

onMounted(async () => {
  try{
    const test = await store.dispatch('PatientRequestStore/fetchInit')
    if(test.data?.data?.status === 'test_completed'){
      chat_completed.value = true
    } else {
      chat_completed.value = false
    }
    const response = await llmStore.createThread();
    if(!llmStore.isInit){
      llmStore.initStore()
    }
    if(response.success){
      await llmStore.fetchThread()
    }
  } catch (e) {
    console.error(e);
  }
  wrapper.value?.addEventListener('scroll', () => {
    isScrolledDown.value = wrapper.value.scrollTop + wrapper.value.clientHeight - wrapper.value.scrollHeight < -100;
  });
});


watch(() => thread.value, async () => {
  await nextTick()
  wrapper.value.scrollTop = wrapper.value?.scrollHeight;
}, { deep: true })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      ref_key: "wrapper",
      ref: wrapper,
      class: "chat-wrapper"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(thread), (msg) => {
        return (_openBlock(), _createBlock(LLMMessage, {
          text: msg.content,
          role: msg.role,
          created_at: msg.created_at,
          key: msg.id
        }, null, 8, ["text", "role", "created_at"]))
      }), 128)),
      (_unref(chat_completed))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "check" }, [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                width: "13",
                height: "12",
                viewBox: "0 0 13 12",
                fill: "none"
              }, [
                _createElementVNode("path", {
                  d: "M11.81 1.72607H10.8822C10.7521 1.72607 10.6287 1.7858 10.549 1.88801L5.07645 8.82064L2.45232 5.49569C2.41263 5.44529 2.36203 5.40453 2.30433 5.37649C2.24663 5.34844 2.18332 5.33383 2.11916 5.33375H1.19136C1.10243 5.33375 1.05332 5.43596 1.10774 5.50498L4.74329 10.1108C4.91319 10.3258 5.23971 10.3258 5.41094 10.1108L11.8936 1.89597C11.948 1.82828 11.8989 1.72607 11.81 1.72607Z",
                  fill: "white"
                })
              ])
            ], -1),
            _createTextVNode(" Test completed ")
          ])))
        : _createCommentVNode("", true)
    ], 512),
    (isScrolledDown.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "downScroll",
          onClick: scrollToBottom
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "12",
            height: "13",
            viewBox: "0 0 12 13",
            fill: "none"
          }, [
            _createElementVNode("path", {
              d: "M6.38992 12.1849L11.9008 5.83529C11.9429 5.78656 11.9664 5.72437 11.9664 5.65882V4.17143C11.9664 4.04706 11.8118 3.98992 11.7311 4.08403L6.62185 9.96975V0.134454C6.62185 0.0605042 6.56135 0 6.4874 0H5.47899C5.40504 0 5.34454 0.0605042 5.34454 0.134454V9.96807L0.235294 4.08235C0.152941 3.98824 0 4.04538 0 4.16975V5.70756C0 5.7395 0.0117647 5.77143 0.0336134 5.79496L5.57647 12.1849C5.62707 12.2431 5.68955 12.2897 5.75972 12.3217C5.82988 12.3536 5.90609 12.3702 5.98319 12.3702C6.0603 12.3702 6.1365 12.3536 6.20667 12.3217C6.27683 12.2897 6.33932 12.2431 6.38992 12.1849Z",
              fill: "white"
            })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (_unref(chat_completed))
      ? (_openBlock(), _createBlock(_unref(Button), {
          key: 1,
          class: "doneBtn",
          onClick: doneHandler
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Done")
          ])),
          _: 1
        }))
      : (_openBlock(), _createBlock(_unref(ChatButton), {
          key: 2,
          suggestions: suggestions.value,
          onClick: submit,
          modelValue: text.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((text).value = $event))
        }, null, 8, ["suggestions", "modelValue"]))
  ]))
}
}

})
import { createService } from '@/services/service'

const apiUrl = '/api/v1'
const resource = 'patient/request'

const { fetchAll } = createService({ resource, apiUrl })

const update = (payload) => {
  return $axios.put(`${apiUrl}/${resource}`, payload)
}

const done = () => {
  return $axios.post(`${apiUrl}/${resource}/done`)
}

const substrate = () => {
  return $axios.post(`${apiUrl}/${resource}/substrate`)
}
const returnConfirm = () => {
  return $axios.post(`${apiUrl}/${resource}/return`)
}

export default {
  fetchAll,
  substrate,
  returnConfirm,
  update,
  done,
}

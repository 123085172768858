import { createService } from '@/services/service'

const apiUrl = '/api/v1'
const resource = 'patient/samples'

const { fetchAll } = createService({ resource, apiUrl })

const update = (payload) => {
  return $axios.put(`${apiUrl}/${resource}`, payload)
}

const labeled = (sample_number) => {
  return $axios.post(`${apiUrl}/${resource}/labeled`, { sample_number })
}
const collected = (sample_number) => {
  return $axios.post(`${apiUrl}/${resource}/collected`, { sample_number })
}

export default {
  fetchAll,
  update,
  labeled,
  collected
}
